import request from './network.js'

// 获取短信验证码
// {
// 	phone:'15188886666'
// }
export function getCode(query) {
  return request({
    url: '/api/ignore',
    method: 'get',
    params: query
  })
}

// 申请开通官网
// {
// 	contactMobile:"string",
// 	contactPerson:"string",//联系人
// 	deptId:1,
// 	remark:"string",
// 	subjectName:"string",//申请主体名字
// }
export function applyWebsit(data) {
  return request({
    url: '/api/ignore/website',
    method: 'post',
	data: data
  })
}

// 申请演出
// {
// 	"addrCity": "",
// 	"addrDistrict": "",
// 	"addrProvince": "",
// 	"address": "",
// 	"allAddress": "",
// 	"contactMobile": "",
// 	"contactPerson": "",
// 	"customNeed": "",
// 	"deptId": 0,
// 	"endTime": "",
// 	"showType": "",
// 	"startTime": "",
// 	"subjectName": ""
// }
export function applyShow(data) {
  return request({
    url: '/api/ignore/show',
    method: 'post',
    data: data
  })
}